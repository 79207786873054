import qlik from './qlik';
import {dimensions, measures} from './qlik/klant/klant';
import {dimensions as bri_dimensions, measures as bri_measures} from './qlik/klant/bri';

export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Klant',
        value: 'klant',
        children: [
          {
            label: 'Naam',
            value: 'vD_Order.DebiteurNaam',
            qlik: bri_dimensions.debtor.name,
            new_value: "dimensions.debtor.name",
          },
          {
            label: 'Nummer',
            value: 'vD_Order.DebiteurNummer',
            qlik: bri_dimensions.debtor.number,
            new_value: "dimensions.debtor.number",
          }
        ]
      },
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            value: 'vD_Order.OrderNummer',
            label: 'Technisch Order Nummer',
            qlik: bri_dimensions.order.number
          },
          // {
          //   value: 'vD_Order.OrderNumberDisplay',
          //   label: 'Order Nummer',
          //   qlik: dimensions.order.ordernumberdisplay

          // },
          // {
          //   value: '!!noginvullen!!',
          //   label: 'Systeem'
          // },
        ]
      },
      {
        label: 'Laad Locatie',
        value: 'laadlocatie',
        children: [
          {
            value: 'vD_Order.LoadingLocation.ID',
            label: 'ID',
            qlik: dimensions.loadinglocation.id
          },
          {
            value: 'vD_Order.LoadingLocation.Name',
            label: 'Naam',
            qlik: dimensions.loadinglocation.name
          },
          {
            value: 'vD_Order.LoadingLocation.ZIPcode',
            label: 'Postcode',
            qlik: dimensions.loadinglocation.zipcode
          },
          {
            value: 'vD_Order.LoadingLocation.City',
            label: 'Plaats',
            qlik: dimensions.loadinglocation.city
          },
          {
            value: 'vD_Order.LoadingLocation.Country',
            label: 'Land',
            qlik: dimensions.loadinglocation.country
          },
          {
            value: 'vD_Order.LoadingLocation.Region',
            label: 'Regio',
            qlik: dimensions.loadinglocation.region
          },
        ]
      },
      {
        label: 'Los Locatie',
        value: 'loslocatie',
        children: [
          {
            value: 'vD_Order.UnloadingLocation.ID',
            label: 'ID',
            qlik: dimensions.unloadinglocation.id

          },
          {
            value: 'vD_Order.UnloadingLocation.Name',
            label: 'Naam',
            qlik: dimensions.unloadinglocation.name
          },
          {
            value: 'vD_Order.UnloadingLocation.ZIPcode',
            label: 'Postcode',
            qlik: dimensions.unloadinglocation.zipcode
          },
          {
            value: 'vD_Order.UnloadingLocation.City',
            label: 'Plaats',
            qlik: dimensions.unloadinglocation.city
          },
          {
            value: 'vD_Order.UnloadingLocation.Country',
            label: 'Land',
            qlik: dimensions.unloadinglocation.country
          },
          {
            value: 'vD_Order.UnloadingLocation.Region',
            label: 'Regio',
            qlik: dimensions.unloadinglocation.region
          },
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'vD_Year',
            label: 'Jaar',
            qlik: dimensions.period.year
          },
          {
            value: 'vD_Quarter',
            label: 'Kwartaal',
            qlik: dimensions.period.quarter
          },
          {
            value: 'vD_Month',
            label: 'Maand',
            qlik: dimensions.period.month
          },
          {
            value: 'vD_Week',
            label: 'Week',
            qlik: dimensions.period.week
          },
          {
            value: 'vD_Date',
            label: 'Datum',
            qlik: dimensions.period.date
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            value: 'vE_OrderOmzet',
            label: 'Totaal',
            qlik: measures.omzet.totaal,
            new_value: "measures.omzet.totaal"
          },
          {
            value: 'vE_OrderOmzetNoTransport',
            label: 'Geen Transport',
            qlik: measures.omzet.notransport
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'vE_OrderKosten',
            label: 'Totaal',
            qlik: measures.kosten.totaal
          },
          {
            value: 'vE_OrderKostenCharter',
            label: 'Derden',
            qlik: measures.kosten.charter
          },
          {
            value: 'vE_OrderKostenRailFerry',
            label: 'Trein',
            qlik: measures.kosten.rail
          },
          {
            value: 'vE_OrderKostenTol',
            label: 'Tol',
            qlik: measures.kosten.toll
          },
          {
            value: 'vE_OrderKostenCleaning',
            label: 'Cleaning',
            qlik: measures.kosten.cleaning
          },
          {
            value: 'vE_OrderKostenCoDriver',
            label: 'Bijrijder',
            qlik: measures.kosten.codriver
          },
          {
            value: 'vE_OrderKostenCollect',
            label: 'Voorhaal',
            qlik: measures.kosten.collect
          },
          {
            value: 'vE_OrderKostenWarehouse',
            label: 'Warehouse',
            qlik: measures.kosten.warehouse
          },
          {
            value: 'vE_OrderKostenOther',
            label: 'Other',
            qlik: measures.kosten.other
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            value: 'vE_OrderAantalOrders',
            label: 'Order',
            qlik: measures.aantal.orders
          },
          {
            value: 'vE_OrderAantalRitten',
            label: 'Ritten',
            qlik: measures.aantal.ritten
          },
          {
            value: 'vE_OrderAantalRittenCoDriver',
            label: 'Ritten met bijrijder',
            qlik: bri_measures.aantal.rittenmetcodriver
          },
          {
            value: 'vE_OrderAantalRittenNoCodriver',
            label: 'Ritten zonder bijrijder',
            qlik: bri_measures.aantal.rittenzondercodriver
          }
          
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'vE_KPI_OrderGoedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur
          },

          {
            value: 'vE_KPI_OrderNormMarginPercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent
          },
          {
            value: 'vE_KPI_OrderNormMarginOverOmzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'vE_KPI_OrderOmzetPerUur',
            label: 'Omzet / Uur',
            qlik: measures.own.omzetperuur
          },
          {
            value: 'vE_KPI_Order_TripOmzetPerUur',
            label: 'Omzet / Uur - rit',
            qlik: measures.own.tripomzetperuur
          },
          {
            value: 'vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrder',
            label: 'Omzet / Uur - rondrit',
            qlik: measures.own.omzetperuurvolgende
          },
          // {
          //   value: 'vE_KPI_OrderBezetting',
          //   label: 'Bezetting'
          // },
          {
            value: 'vE_OrderNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            qlik: measures.own.normomzetperuur
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed',
            qlik: measures.outsourced.percentage
          },
          {
            value: 'vE_KPI_OrderCharterMarge',
            label: 'Derden Marge',
            qlik: measures.outsourced.marge
          },
          {
            value: 'vE_OrderNormCharter',
            label: 'Norm',
            qlik: measures.outsourced.norm
          },
        ]
      },
      {
        value: 'potency',
        label: 'Potentie',
        children: [
          {
            value: 'vE_OrderPotency',
            label: 'Totaal',
            qlik: measures.potentie.totaal
          },
          {
            value: 'vE_OrderPotencyCommercial',
            label: 'Commercieel',
            qlik: measures.potentie.commercieel
          },
          {
            value: 'vE_OrderPotencyOperational',
            label: 'Operationeel',
            qlik: measures.potentie.operationeel
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'vE_OrderKM',
            label: 'Totaal',
            qlik: measures.km.km
          },
          {
            value: 'vE_OrderKMFull',
            label: 'Beladen',
            qlik: measures.km.kmfull
          },
          {
            value: 'vE_OrderKMEmpty',
            label: 'Onbeladen',
            qlik: measures.km.kmempty
          },
          {
            value: 'vE_KPI_OrderOmzetPerKM',
            label: 'Omzet / KM',
            qlik: measures.km.omzetperkm
          },
          {
            value: 'vE_KPI_OrderOmzetPerKMFull',
            label: 'Omzet / beladen KM',
            qlik: measures.km.omzetperkmfull
          },
          {
            value: 'vE_KPI_OrderBelading',
            label: '% Beladen',
            qlik: measures.km.belading
          },
          {
            value: 'vE_OrderNormTurnoverKM',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm
          },
          {
            value: 'vE_OrderNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM',
            qlik: measures.km.normomzetperkmfull
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'vE_OrderUren',
            label: 'Totaal (Σ)',
            qlik: measures.uren.totaal
          },
          {
            value: 'vE_OrderUrenLoading',
            label: 'Laden (Σ)',
            qlik: measures.uren.loading
          },
          {
            value: 'vE_OrderUrenUnloading',
            label: 'Lossen (Σ)',
            qlik: measures.uren.unloading
          },
          {
            value: 'vE_OrderUrenDriving',
            label: 'Rijden (Σ)',
            qlik: measures.uren.driving
          },
          {
            value: 'vE_OrderUrenOther',
            label: 'Overig (Σ)',
            qlik: measures.uren.other
          },
          {
            value: 'vE_OrderUrenAvg',
            label: 'Totaal (avg)',
            qlik: measures.uren.avg_totaal
          },
          {
            value: 'vE_OrderUrenAvgLoading',
            label: 'Laden (avg)',
            qlik: measures.uren.avg_loading
          },
          {
            value: 'vE_OrderUrenAvgUnloading',
            label: 'Lossen (avg)',
            qlik: measures.uren.avg_unloading
          },
          {
            value: 'vE_OrderUrenAvgDriving',
            label: 'Rijden (avg)',
            qlik: measures.uren.avg_driving
          },
          {
            value: 'vE_OrderUrenAvgOther',
            label: 'Overig (avg)',  
            qlik: measures.uren.avg_other    
          },
        ]
      },
      {
        value: 'vergelijkperiode',
        label: 'Voorgaand jaar',
        children: [
          {
            value: 'vE_OrderOmzetVorigJaar',
            label: 'Omzet',
            qlik: measures.previousyear.omzet 
          },
          {
            value: 'vE_OrderAantalOrdersVorigJaar',
            label: 'Order',
            qlik: measures.previousyear.orders 
          },
          {
            value: 'vE_OrderAantalRittenVorigJaar',
            label: 'Ritten',
            qlik: measures.previousyear.ritten
          },
          {
            value: 'vE_OrderAantalRittenVorigJaarMetBijrijder',
            label: 'Ritten met bijrijder',
            qlik: bri_measures.previousyear.rittenmetcodriver
          },
          {
            value: 'vE_OrderAantalRittenVorigJaarZonderBijrijder',
            label: 'Ritten zonder bijrijder',
            qlik: bri_measures.previousyear.rittenzondercodriver
          },
          {
            value: 'vE_KPI_OrderGoedkeurVorigJaar',
            label: 'OK',
            qlik: measures.previousyear.goedkeur
          },
          {
            value: 'vE_KPI_OrderNormMarginPercentVorigJaar',
            label: 'Norm Marge %',
            qlik: measures.previousyear.normmarge
          },
          {
            value: 'vE_KPI_OrderNormMarginOverOmzetVorigJaar',
            label: 'Norm Marge',
            qlik: measures.previousyear.normmargeomzet  
          },
          {
            value: 'vE_KPI_OrderOmzetPerUurVorigJaar',
            label: 'Omzet / Uur',
            qlik: measures.previousyear.omzetperuur
          },
          {
            value: 'vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrderVorigJaar',
            label: 'Omzet / Uur - rondrit',
            qlik: bri_measures.previousyear.tripomzetperuur
          },
          {
            value: 'vE_KPI_OrderTripOutsourcedVorigJaar',
            label: '% Uitbesteed',
            qlik: measures.previousyear.uitbesteed
          },
          {
            value: 'vE_KPI_OrderCharterMargeVorigJaar',
            label: 'Derden Marge',
            qlik: measures.previousyear.chargermarge 
          },

        ],
      },
      {
        value: 'persize',
        label: 'Per Eenheid',
        children: [
          {
            value: 'vE_OrderShipmentSize',
            label: 'Ton',
            qlik: measures.persize.shipmentsize 
          },
          {
            value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
            label: 'Omzet Per Ton',
            qlik: measures.persize.omzet_shipmentsize 
          },
          {
            value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
            label: 'Laad minuten per Ton',
            qlik: measures.persize.loading_shipmentsize 
          },
          {
            value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
            label: 'Los minuten per ton',
            qlik: measures.persize.unloading_shipmentsize 
          },
          {
            value: 'vE_KPI_OrderMinutenPerZendingGrootte',
            label: 'Tijd per ton',
            qlik: measures.persize.time_shipmentsize
          },
        ]
      },
    ]
  }
];
