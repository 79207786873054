
import {dimensions, measures} from './qlik/qlik';
import {measures as co2measures} from './qlik/co2';
export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Chauffeur',
        value: 'driver',
        children: [
          {
            qlik: dimensions.driver.name,
            label: 'Naam',
            value: 'vD_Driver.Name'
          },
          {
            qlik: dimensions.driver.number,
            label: 'Nummer',
            value: 'vD_Driver.Number'
          }
        ]
      },
      {
        label: 'Auto',
        value: 'vehicle',
        children: [
          {
            qlik: dimensions.trip.plangroup,
            value: 'vD_Trip.PlanGroup',
            label: 'Afdeling'
          },
          {
            qlik: dimensions.vehicle.license,
            label: 'Kenteken',
            value: 'vD_Vehicle.License'
          },
          {
            label: 'Nummer',
            value: 'vD_Vehicle.Number',
            qlik: dimensions.vehicle.number,
          }
        ]
      },
      {
        label: 'Crediteur',
        value: 'crediteur',
        children: [
          {
            label: 'Naam',
            value: 'vD_Trip.Creditor.Name',
            qlik: dimensions.charter.name
          },
          {
            qlik: dimensions.charter.number,
            label: 'Nummer',
            value: 'vD_Trip.Creditor.Number'
          }
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            qlik: dimensions.trip.number,
            value: 'vD_Trip.TripNumber',
            label: 'Ritnummer'
          },
        ]
      },
      {
        label: 'Start Locatie',
        value: 'laadlocatie',
        children: [
          {
            qlik: dimensions.startlocation.id,
            value: 'vD_Trip.StartLocation.ID',
            label: 'ID'
          },
          {
            qlik: dimensions.startlocation.name,
            value: 'vD_Trip.StartLocation.Name',
            label: 'Naam'
          },
          { 
            qlik: dimensions.startlocation.adress,
            value: 'vD_Trip.StartLocation.Adress',
            label: 'Adress'
          },
          {
            qlik: dimensions.startlocation.zipcode,
            value: 'vD_Trip.StartLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            qlik: dimensions.startlocation.city,
            value: 'vD_Trip.StartLocation.City',
            label: 'Plaats'
          },
          {
            qlik: dimensions.startlocation.district,
            value: 'vD_Trip.StartLocation.District',
            label: 'Gemeente'
          },
          {
            qik: dimensions.startlocation.province,
            value: 'vD_Trip.StartLocation.Province',
            label: 'Provincie'
          },
          {
            qlik: dimensions.startlocation.country,
            value: 'vD_Trip.StartLocation.Country',
            label: 'Land'
          },
          {
            qlik: dimensions.startlocation.land,
            value: 'vD_Trip.StartLocation.Land',
            label: 'Land (ISO)'
          },
        ]
      },
      {
        label: 'Eind Locatie',
        value: 'loslocatie',
        children: [
          {
            qlik: dimensions.endlocation.id,
            value: 'vD_Trip.EndLocation.ID',
            label: 'ID'
          },
          {
            qlik: dimensions.endlocation.name,
            value: 'vD_Trip.EndLocation.Name',
            label: 'Naam'
          },
          {
            qlik: dimensions.endlocation.adress,
            value: 'vD_Trip.EndLocation.Adress',
            label: 'Adres'
          },
          {
            qlik: dimensions.endlocation.zipcode,
            value: 'vD_Trip.EndLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            qlik: dimensions.endlocation.city,
            value: 'vD_Trip.EndLocation.City',
            label: 'Plaats'
          },
          {
            qlik: dimensions.endlocation.district,
            value: 'vD_Trip.EndLocation.District',
            label: 'Gemeente'
          },
          {
            qlik: dimensions.endlocation.province,
            value: 'vD_Trip.EndLocation.Province',
            label: 'Provincie'
          },
          {
            qlik: dimensions.endlocation.country,
            value: 'vD_Trip.EndLocation.Country',
            label: 'Land'
          },
          {
            qlik: dimensions.endlocation.land,
            value: 'vD_Trip.EndLocation.Land',
            label: 'Land (ISO)'
          },
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            qlik: dimensions.period.year,
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            qlik: dimensions.period.quarter,
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            qlik: dimensions.period.month,
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            qlik: dimensions.period.week,
            value: 'vD_Week',
            label: 'Week'
          },
          {
            qlik: dimensions.period.date,
            value: 'vD_Date',
            label: 'Datum'
          },
          {
            qlik: dimensions.period.weekday,
            value: 'vD_Weekday',
            label: 'Weekday'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            qlik: measures.omzet.totaal,
            value: 'vE_TripOmzet',
            label: 'Totaal'
          },
          {
            qlik: measures.omzet.freight,
            value: 'vE_TripOmzet.Vracht',
            label: 'Vracht'
          },
          {
            qlik: measures.omzet.fuel,
            value: 'vE_TripOmzet.Fuel',
            label: 'Brandstof'
          },
          {
            qlik: measures.omzet.other,
            value: 'vE_TripOmzet.Other',
            label: 'Overig'
          }
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            qlik: measures.kosten.totaal,
            value: 'vE_TripKosten',
            label: 'Totaal',
          },
          {
            qlik: measures.kosten.charter,
            value: 'vE_TripKostenCharter',
            label: 'Derden',
          },
          {
            qlik: measures.kosten.toll, 
            value: 'vE_TripKostenTol',
            label: 'Tol',
          },
          {
            qlik: measures.kosten.codriver,
            value: 'vE_TripKostenCoDriver',
            label: 'Bijrijder',
          },
          {
            qlik: measures.kosten.warehouse,
            value: 'vE_TripKostenWarehouse',
            label: 'Warehouse',
          },
          {
            qlik: measures.kosten.other,
            value: 'vE_TripKostenOther',
            label: 'Other',
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            qlik: measures.aantal.orders,
            value: 'vE_TripAantalOrders',
            label: 'Order',
          },
          {
            qlik: measures.aantal.ritten,
            value: 'vE_TripAantalRitten',
            label: 'Ritten',
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            qlik: measures.kpi.goedkeur,
            value: 'vE_KPI_TripGoedkeur',
            label: 'OK'
          },

          {
            qlik: measures.kpi.normmarginpercent,
            value: 'vE_KPI_TripNormMarginPercent',
            label: 'Norm Marge %'
          },
          {
            qlik: measures.kpi.normmarginomzet,
            value: 'vE_KPI_TripNormMarginOverOmzet',
            label: 'Norm Marge'
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            qlik: measures.own.omzetperuur,
            value: 'vE_KPI_TripOmzetPerUur',
            label: 'Omzet / Uur'
          },
          {
            qlik: measures.own.normomzetperuur,
            value: 'vE_TripNormTurnoverHour',
            label: 'Norm Omzet / Uur'
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            qlik: measures.outsourced.percentage,
            value: 'vE_KPI_TripTripOutsourced',
            label: '% Uitbesteed'
          },
          {
            qlik: measures.outsourced.marge,
            value: 'vE_KPI_TripCharterMarge',
            label: 'Derden Marge'
          },
          {
            qlik: measures.outsourced.norm,
            value: 'vE_TripNormCharter',
            label: 'Norm'
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            qlik: measures.km.km,
            value: 'vE_TripKM',
            label: 'Totaal'
          },
          {
            qlik: measures.km.omzetperkm,
            value: 'vE_KPI_TripOmzetPerKM',
            label: 'Omzet / KM'
          },
          {
            qlik: measures.km.normomzetperkm,
            value: 'vE_TripNormTurnoverKM',
            label: 'Norm Omzet / KM'
          },
        ]
      },
      {
        value: 'CO2',
        label: 'CO2',
        children: [
          {
            qlik: co2measures.co2.rekenkilometers,
            value: 'vE_TripCo2KM',
            label: 'Rekenkilometers',
            help: 'De kilometers voor de CO2, indien niet aanwezig worden zijn deze geinterpoleerd op basis van de afstand en zendinggrootte'
          },
          {
            qlik: co2measures.co2.verbruikperkm,
            value: 'vE_TripCo2Verbruik',
            label: 'Vebruik per km',
            help: 'Het brandstofverbruik per kilometer, op dit moment een vaste norm vastgesteld op 0.3 liter per km'
          },
          {
            qlik: co2measures.co2.litersbrandstof,
            value: 'vE_TripCo2L',
            label: 'Liters Brandstof',
            help: 'Het aantal liters brandstof op basis van het aantal rekenkilometers in combinatie met het brandstofverbruik'
          },
          {
            qlik: co2measures.co2.uitstootfactorwtw,
            value: 'vE_TripCo2FactorWTW',
            label: 'Uitstoot factor WTW',
            help: 'De factor voor de uitstoot van een liter brandstof WellToWheel (WTW)'
          },
          {
            qlik: co2measures.co2.uitstootfactorttw,
            value: 'vE_TripCo2FactorTTW',
            label: 'Uitstoot factor TTW',
            help: 'De factor voor de uitstoot van een liter brandstof TankToWheel (TTW)'
          },
          {
            qlik: co2measures.co2.co2uitstootwtw,
            value: 'vE_TripCo2TotaalWTW',
            label: 'CO2 uitstoot (WTW)',
          },
          {
            qlik: co2measures.co2.co2uitstootttw,
            value: 'vE_TripCo2TotaalTTW',
            label: 'CO2 uitstoot (TTW)',
          }
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            qlik: measures.uren.totaal,
            value: 'vE_TripUren',
            label: 'Totaal (Σ)',
          },
          {
            qlik: measures.uren.loading,
            value: 'vE_TripUrenLoading',
            label: 'Laden (Σ)',
          },
          {
            qlik: measures.uren.unloading, 
            value: 'vE_TripUrenUnloading',
            label: 'Lossen (Σ)',
          },
          {
            qlik: measures.uren.driving,
            value: 'vE_TripUrenDriving',
            label: 'Rijden (Σ)',
          },
          {
            qlik: measures.uren.other, 
            value: 'vE_TripUrenOther',
            label: 'Overig (Σ)',
          },
          {
            qlik: measures.uren.avg_totaal,
            value: 'vE_TripUrenAvg',
            label: 'Totaal (avg)',
          },
          {
            qlik: measures.uren.avg_loading,
            value: 'vE_TripUrenAvgLoading',
            label: 'Laden (avg)',
          },
          {
            qlik: measures.uren.avg_unloading,
            value: 'vE_TripUrenAvgUnloading',
            label: 'Lossen (avg)',
          },
          {
            qlik: measures.uren.avg_driving,
            value: 'vE_TripUrenAvgDriving',
            label: 'Rijden (avg)',
          },
          {
            qlik: measures.uren.avg_other,
            value: 'vE_TripUrenAvgOther',
            label: 'Overig (avg)',      // $(wActive(Order, 'M_Uren_Rijden_A'))
          }
        ]
      },
      {
        value: 'vergelijkperiode',
        label: 'Voorgaand jaar',
        children: [
          {
            qlik: measures.previousyear.omzet,
            value: 'vE_TripOmzetVorigJaar',
            label: 'Omzet',
          },
          {
            qlik: measures.previousyear.orders,
            value: 'vE_TripAantalOrdersVorigJaar',
            label: 'Order',
          },
          {
            qlik: measures.previousyear.ritten,
            value: 'vE_TripAantalRittenVorigJaar',
            label: 'Ritten',
          },
          {
            qlik: measures.previousyear.goedkeur,
            value: 'vE_KPI_TripGoedkeurVorigJaar',
            label: 'OK'
          },

          {
            qlik: measures.previousyear.normmarge,
            value: 'vE_KPI_TripNormMarginPercentVorigJaar',
            label: 'Norm Marge %'
          },
          {
            qlik: measures.previousyear.normmargeomzet,
            value: 'vE_KPI_TripNormMarginOverOmzetVorigJaar',
            label: 'Norm Marge'
          },
          {
            qlik: measures.previousyear.omzetperuur,
            value: 'vE_KPI_TripOmzetPerUurVorigJaar',
            label: 'Omzet / Uur'
          },
          {
            qlik: measures.previousyear.uitbesteed,
            value: 'vE_KPI_TripTripOutsourcedVorigJaar',
            label: '% Uitbesteed'
          },
          {
            qlik: measures.previousyear.chargermarge,
            value: 'vE_KPI_TripCharterMargeVorigJaar',
            label: 'Derden Marge'
          },

        ],
      },
    ]
  }
];
