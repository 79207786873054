import qlik from '../orders/qlik';
import {dimensions, measures} from './qlik/qlik';
import {dimensions as bri_dimensions} from './qlik/bri';
import {measures as bri_measures} from './qlik/bri';

export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Chauffeur',
        value: 'driver',
        children: [
          {
            label: 'Naam',
            value: 'vD_Driver.Name',
            qlik: dimensions.driver.name
          },
          {
            label: 'Nummer',
            value: 'vD_Driver.Number',
            qlik: dimensions.driver.number
          },
          {
            label: 'Bijrijder',
            value: 'vD_Trip.CoDriverName',
            qlik: bri_dimensions.driver.codrivername
          }
        ]
      },
      {
        label: 'Auto',
        value: 'vehicle',
        children: [
          {
            label: 'Naam',
            value: 'vD_Vehicle.Number',
            qlik: dimensions.vehicle.number
          },
          {
            label: 'Nummer',
            value: 'vD_Vehicle.Name',
            qlik: dimensions.vehicle.name
          },
          {
            label: 'Type',
            value: 'dimensions.vehicle.type',
            qlik: dimensions.vehicle.type
          }
        ]
      },
      {
        label: 'Crediteur',
        value: 'crediteur',
        children: [
          {
            label: 'Naam',
            value: 'vD_Trip.CreditorName',
            qlik: dimensions.charter.name
          },
          {
            label: 'Nummer',
            value: 'vD_Trip.CreditorNumber',
            qlik: dimensions.charter.number
          }
        ]
      },
      {
        label: 'Trailer',
        value: 'trailer',
        children: [
          {
            label: 'Nummer',
            value: 'vD_Trip.TrailerNumber',
            qlik: dimensions.trailer.number
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            value: 'vD_Trip.TripNumber',
            label: 'Technisch Trip Nummer',
            qlik: dimensions.trip.number
          },
        ]
      },
      {
        label: 'Start Locatie',
        value: 'laadlocatie',
        children: [
          {
            value: 'vD_Trip.StartLocation.ID',
            label: 'ID',
            qlik: dimensions.startlocation.id
          },
          {
            value: 'vD_Trip.StartLocation.Name',
            label: 'Naam',
            qlik: dimensions.startlocation.name
          },
          {
            value: 'vD_Trip.StartLocation.ZIPcode',
            label: 'Postcode',
            qlik: dimensions.startlocation.zipcode
          },
          {
            value: 'vD_Trip.StartLocation.City',
            label: 'Plaats',
            qlik: dimensions.startlocation.city
          },
          {
            value: 'vD_Trip.StartLocation.Country',
            label: 'Land',
            qlik: dimensions.startlocation.country
          },
          {
            value: 'vD_Trip.StartLocation.Region',
            label: 'Regio',
            qlik: dimensions.startlocation.region
          },
        ]
      },
      {
        label: 'Eind Locatie',
        value: 'loslocatie',
        children: [
          {
            value: 'vD_Trip.EndLocation.ID',
            label: 'ID',
            qlik: dimensions.endlocation.id
          },
          {
            value: 'vD_Trip.EndLocation.Name',
            label: 'Naam',
            qlik: dimensions.endlocation.name
          },
          {
            value: 'vD_Trip.EndLocation.ZIPcode',
            label: 'Postcode',
            qlik: dimensions.endlocation.zipcode
          },
          {
            value: 'vD_Trip.EndLocation.City',
            label: 'Plaats',
            qlik: dimensions.endlocation.city
          },
          {
            value: 'vD_Trip.EndLocation.Country',
            label: 'Land',
            qlik: dimensions.endlocation.country
          },
          {
            value: 'vD_Trip.EndLocation.Region',
            label: 'Regio',
            qlik: dimensions.endlocation.region
          },
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'vD_Year',
            label: 'Jaar',
            qlik: dimensions.period.year
          },
          {
            value: 'vD_Quarter',
            label: 'Kwartaal',
            qlik: dimensions.period.quarter
          },
          {
            value: 'vD_Month',
            label: 'Maand',
            qlik: dimensions.period.month
          },
          {
            value: 'vD_Week',
            label: 'Week',
            qlik: dimensions.period.week
          },
          {
            value: 'vD_Date',
            label: 'Datum',
            qlik: dimensions.period.date
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            value: 'vE_TripOmzet',
            label: 'Totaal',
            qlik: measures.omzet.totaal
          },
          {
            value: 'vE_TripOmzetNoTransport',
            label: 'Geen Transport',
            qlik: measures.omzet.notransport
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'vE_TripKosten',
            label: 'Totaal',
            qlik: measures.kosten.totaal
          },
          {
            value: 'vE_TripKostenCharter',
            label: 'Derden',
            qlik: measures.kosten.charter
          },
          {
            value: 'vE_TripKostenRailFerry',
            label: 'Trein',
            qlik: measures.kosten.rail
          },
          {
            value: 'vE_TripKostenTol',
            label: 'Tol',
            qlik: measures.kosten.toll
          },
          {
            value: 'vE_TripKostenCleaning',
            label: 'Cleaning',
            qlik: measures.kosten.cleaning
          },
          {
            value: 'vE_TripKostenCoDriver',
            label: 'Bijrijder',
            qlik: measures.kosten.codriver
          },
          {
            value: 'vE_TripKostenCollect',
            label: 'Voorhaal',
            qlik: measures.kosten.collect
          },
          {
            value: 'vE_TripKostenWarehouse',
            label: 'Warehouse',
            qlik: measures.kosten.warehouse
          },
          {
            value: 'vE_TripKostenOther',
            label: 'Other',
            qlik: measures.kosten.other
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            value: 'vE_TripAantalOrders',
            label: 'Order',
            qlik: measures.aantal.orders
          },
          {
            value: 'vE_TripAantalRitten',
            label: 'Ritten',
            qlik: measures.aantal.ritten
          },
          {
            value: 'vE_TripAantalRittenCoDriver',
            label: 'Ritten met bijrijder',
            qlik: bri_measures.aantal.rittenmetcodriver
          },
          {
            value: 'vE_TripAantalRittenNoCodriver',
            label: 'Ritten zonder bijrijder',
            qlik: bri_measures.aantal.rittenzondercodriver
          }
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'vE_KPI_TripGoedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur
          },

          {
            value: 'vE_KPI_TripNormMarginPercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent
          },
          {
            value: 'vE_KPI_TripNormMarginOverOmzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'vE_KPI_TripOmzetPerUur',
            label: 'Omzet / Uur',
            qlik: measures.own.omzetperuur
          },
          {
            value: 'vE_TripNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            qlik: measures.own.normomzetperuur
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'vE_KPI_TripTripOutsourced',
            label: '% Uitbesteed',
            qlik: measures.outsourced.percentage
          },
          {
            value: 'vE_KPI_TripCharterMarge',
            label: 'Derden Marge',
            qlik: measures.outsourced.marge
          },
          {
            value: 'vE_TripNormCharter',
            label: 'Norm',
            qlik: measures.outsourced.norm
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'vE_TripKM',
            label: 'Totaal',
            qlik: measures.km.km
          },
          {
            value: 'vE_TripKMFull',
            label: 'Beladen',
            qlik: measures.km.kmfull
          },
          {
            value: 'vE_TripKMEmpty',
            label: 'Onbeladen',
            qlik: measures.km.kmempty
          },
          {
            value: 'vE_KPI_TripOmzetPerKM',
            label: 'Omzet / KM',
            qlik: measures.km.omzetperkm
          },
          {
            value: 'vE_KPI_TripOmzetPerKMFull',
            label: 'Omzet / beladen KM',
            qlik: measures.km.omzetperkmfull
          },
          {
            value: 'vE_TripNormTurnoverKM',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm
          },
          {
            value: 'vE_TripNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM',
            qlik: measures.km.normomzetperkmfull
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'vE_TripUren',
            label: 'Totaal (Σ)',
            qlik: measures.uren.totaal
          },
          {
            value: 'vE_TripUrenLoading',
            label: 'Laden (Σ)',
            qlik: measures.uren.loading
          },
          {
            value: 'vE_TripUrenUnloading',
            label: 'Lossen (Σ)',
            qlik: measures.uren.unloading
          },
          {
            value: 'vE_TripUrenDriving',
            label: 'Rijden (Σ)',
            qlik: measures.uren.driving
          },
          {
            value: 'vE_TripUrenOther',
            label: 'Overig (Σ)',
            qlik: measures.uren.other
          },
          {
            value: 'vE_TripUrenAvg',
            label: 'Totaal (avg)',
            qlik: measures.uren.avg_totaal
          },
          {
            value: 'vE_TripUrenAvgLoading',
            label: 'Laden (avg)',
            qlik: measures.uren.avg_loading
          },
          {
            value: 'vE_TripUrenAvgUnloading',
            label: 'Lossen (avg)',
            qlik: measures.uren.avg_unloading
          },
          {
            value: 'vE_TripUrenAvgDriving',
            label: 'Rijden (avg)',
            qlik: measures.uren.avg_driving
          },
          {
            value: 'vE_TripUrenAvgOther',
            label: 'Overig (avg)',
            qlik: measures.uren.avg_other     
          },
        ]
      },
      {
        value: 'vergelijkperiode',
        label: 'Voorgaand jaar',
        children: [
          {
            value: 'vE_TripOmzetVorigJaar',
            label: 'Omzet',
            qlik: measures.previousyear.omzet
          },
          {
            value: 'vE_TripAantalOrdersVorigJaar',
            label: 'Order',
            qlik: measures.previousyear.orders
          },
          {
            value: 'vE_TripAantalRittenVorigJaar',
            label: 'Ritten',
            qlik: measures.previousyear.ritten
          },
          {
            value: 'vE_KPI_TripGoedkeurVorigJaar',
            label: 'OK',
            qlik: measures.previousyear.goedkeur
          },

          {
            value: 'vE_KPI_TripNormMarginPercentVorigJaar',
            label: 'Norm Marge %',
            qlik: measures.previousyear.normmarge
          },
          {
            value: 'vE_KPI_TripNormMarginOverOmzetVorigJaar',
            label: 'Norm Marge',
            qlik: measures.previousyear.normmargeomzet
          },
          {
            value: 'vE_KPI_TripOmzetPerUurVorigJaar',
            label: 'Omzet / Uur',
            qlik: measures.previousyear.omzetperuur
          },
          // {
          //   value: 'vE_KPI_TripOmzetPerUurCombinatieVolgendeOrderVorigJaar',
          //   label: 'Omzet / Uur - rondrit',
          //   qlik: bri_measures.previousyear.omzetperuurrit
          // },
          {
            value: 'vE_KPI_TripTripOutsourcedVorigJaar',
            label: '% Uitbesteed',
            qlik: measures.previousyear.uitbesteed
          },
          {
            value: 'vE_KPI_TripCharterMargeVorigJaar',
            label: 'Derden Marge',
            qlik: measures.previousyear.chargermarge
          },

        ],
      },
      {
        value: 'persize',
        label: 'Per Eenheid',
        children: [
          {
            value: 'vE_TripShipmentSize',
            label: 'Ton', 
            qlik: measures.persize.shipmentsize
          },
          {
            value: 'vE_KPI_TripOmzetPerZendingGroottePervE_TripShipmentSize',
            label: 'Omzet Per Ton', 
            qlik: measures.persize.omzet_shipmentsize
          },
          {
            value: 'vE_KPI_TripLaadMinutenPerZendingGrootte',
            label: 'Laad minuten per Ton',
            qlik: measures.persize.loading_shipmentsize
          },
          {
            value: 'vE_KPI_TripLosMinutenPerZendingGrootte',
            label: 'Los minuten per ton',
            qlik: measures.persize.unloading_shipmentsize
          },
          {
            value: 'vE_KPI_TripMinutenPerZendingGrootte',
            label: 'Tijd per ton',
            qlik: measures.persize.time_shipmentsize
          },
        ]
      },
    ]
  }
];
