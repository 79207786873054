const measures = {
    omzet: {
        totaal: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripOmzet)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripOmzet)' & ' > ' & '$(vB_Alle)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        freight:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripOmzet(Kind={'Freight'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripOmzet)' & ' > ' & '$(vB_Freight)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        toll:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripOmzet(Kind={'Fuel'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripOmzet)' & ' > ' & '$(vB_Fuel)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        fuel: {
          "qLibraryId": "",
          "qDef": {
            "qLabel": "OrderOmzet",
            "qDescription": "",
            "qTags": [],
            "qGrouping": "N",
            "qDef": "$(vE_OrderTripOmzet(Kind={'Fuel'}))",
            "qNumFormat": {
              "qType": "U",
              "qnDec": 10,
              "qUseThou": 0,
              "qFmt": "",
              "qDec": "",
              "qThou": ""
            },
            "qRelative": false,
            "qBrutalSum": false,
            "qAggrFunc": "Expr",
            "qAccumulate": 0,
            "qReverseSort": false,
            "qActiveExpression": 0,
            "qExpressions": [],
            "qLabelExpression": "='$(vL_OrderTripOmzet)' & ' > ' & '$(vB_Fuel)'",
            "autoSort": true,
            "numFormatFromTemplate": true,
            "textAlign": {
              "auto": true,
              "align": "left"
            }
          },
          "qSortBy": {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": -1,
            "qSortByAscii": 0,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCond": {
            "qv": ""
          },
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        maut:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripOmzet(Kind={'Maut'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripOmzet)' & ' > ' & '$(vB_Maut)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        extra:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripOmzet(Kind={'Extra Kosten'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripOmzet)' & ' > ' & 'Extra Kosten'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        other:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripOmzet(Kind={'Other'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripOmzet)' & ' > ' & '$(vB_Other)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        notransport:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripOmzetNoTransport)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripOmzetNoTransport)'",
              "autoSort": true,
              "cId": "qSZzhj",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    kosten: {
        totaal: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripKosten)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & '$(vB_Alle)'",
              "autoSort": true,
              "cId": "eUNc",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        charter:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripKosten(Kind={'Charter'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & '$(vB_Charter)'",
              "autoSort": true,
              "cId": "epyyN",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        rail:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripKosten(Kind={'RailFerry'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & '$(vB_RailFerry)'",
              "autoSort": true,
              "cId": "uwdtmaD",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        toll:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripKosten(Kind={'Toll'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & '$(vB_Toll)'",
              "autoSort": true,
              "cId": "fKyACW",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        cleaning:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripKosten(Kind={'Cleaning'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & '$(vB_Cleaning)'",
              "autoSort": true,
              "cId": "sjpWGCN",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        codriver:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripKosten(Kind={'CoDriver'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & '$(vB_CoDriver)'",
              "autoSort": true,
              "cId": "YjeF",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        collect:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripKosten(Kind={'Collect'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & '$(vB_Collect)'",
              "autoSort": true,
              "cId": "VPUAJN",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        warehouse:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripKosten(Kind={'Warehouse'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & '$(vB_Warehouse)'",
              "autoSort": true,
              "cId": "aJk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        km:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripKosten(Kind={'KM'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & 'KM'",
              "autoSort": true,
              "cId": "aJk1",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        other:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripKosten(Kind={'Other'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & '$(vB_Other)'",
              "autoSort": true,
              "cId": "ucMQue",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        jib:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripKosten(Kind={'JIB'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & 'JIB'",
              "autoSort": true,
              "cId": "ucMQue",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        boat:  {
              "qLibraryId": "",
              "qDef": {
                "qLabel": "",
                "qDescription": "",
                "qTags": [],
                "qGrouping": "N",
                "qDef": "$(vE_OrderTripKosten(Kind={'Ferry'}))",
                "qNumFormat": {
                  "qType": "U",
                  "qnDec": 10,
                  "qUseThou": 0,
                  "qFmt": "",
                  "qDec": "",
                  "qThou": ""
                },
                "qRelative": false,
                "qBrutalSum": false,
                "qAggrFunc": "Expr",
                "qAccumulate": 0,
                "qReverseSort": false,
                "qActiveExpression": 0,
                "qExpressions": [],
                "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & 'Boot'",
                "autoSort": true,
                "cId": "ucMQue",
                "numFormatFromTemplate": true,
                "textAlign": {
                  "auto": true,
                  "align": "left"
                }
              },
              "qSortBy": {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": -1,
                "qSortByAscii": 0,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              },
              "qAttributeExpressions": [],
              "qAttributeDimensions": [],
              "qCalcCond": {
                "qv": ""
              },
              "qCalcCondition": {
                "qCond": {
                  "qv": ""
                },
                "qMsg": {
                  "qv": ""
                }
              }
            },
        fuelcorrection:{
                      "qLibraryId": "",
                      "qDef": {
                        "qLabel": "",
                        "qDescription": "",
                        "qTags": [],
                        "qGrouping": "N",
                        "qDef": "$(vE_OrderTripKosten(Kind={'FuelCorrection'}))",
                        "qNumFormat": {
                          "qType": "U",
                          "qnDec": 10,
                          "qUseThou": 0,
                          "qFmt": "",
                          "qDec": "",
                          "qThou": ""
                        },
                        "qRelative": false,
                        "qBrutalSum": false,
                        "qAggrFunc": "Expr",
                        "qAccumulate": 0,
                        "qReverseSort": false,
                        "qActiveExpression": 0,
                        "qExpressions": [],
                        "qLabelExpression": "='$(vL_OrderTripKosten)' & ' > ' & 'Correctie brandstof'",
                        "autoSort": true,
                        "cId": "ucMQue",
                        "numFormatFromTemplate": true,
                        "textAlign": {
                          "auto": true,
                          "align": "left"
                        }
                      },
                      "qSortBy": {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": -1,
                        "qSortByAscii": 0,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                          "qv": ""
                        },
                        "qSortByGreyness": 0
                      },
                      "qAttributeExpressions": [],
                      "qAttributeDimensions": [],
                      "qCalcCond": {
                        "qv": ""
                      },
                      "qCalcCondition": {
                        "qCond": {
                          "qv": ""
                        },
                        "qMsg": {
                          "qv": ""
                        }
                      }
                },
    },
    aantal: {
        dossiers: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderAantalOrders",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripAantalDossiers)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripAantalDossiers)'",
              "autoSort": true,
              "cId": "UUmPzu",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        orders:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderAantalOrders",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripAantalOrders)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripAantalOrders)'",
              "autoSort": true,
              "cId": "UUmPzu",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        ritten:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderAantalRitten",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripAantalRitten)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripAantalRitten)'",
              "autoSort": true,
              "cId": "PjAQRY",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        afwijkingen:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderAantalAfwijkingen",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_OrderTripAantalAfwijkingen)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderTripAantalAfwijkingen)'",
              "autoSort": true,
              "cId": "PjAQRY",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    kpi: {
        goedkeur: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderPercGoedkeur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderTripGoedkeur)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderTripGoedkeur)'",
              "autoSort": true,
              "cId": "Rnnjp",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        normmarginpercent:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderMarge",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderTripNormMarginPercent)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderNormMarginPercent)'",
              "autoSort": true,
              "cId": "gkeLVN",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderTripNormMarginPercent) < 0 ,rgb(255,0,0), rgb(0,127,0))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        normmarginomzet:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderTripNormMarginOverOmzet)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderNormMarginOverOmzet)'",
              "autoSort": true,
              "cId": "axZsAXz",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderTripNormMarginPercent) < 0 ,rgb(255,0,0), rgb(0,127,0))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    own: {
        omzetperuur: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzetPerUur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderTripOmzetPerUur)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderOmzetPerUur)'",
              "autoSort": true,
              "cId": "MJZcvk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderTripOmzetPerUur) < 0.85 * $(vE_OrderTripNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_OrderTripOmzetPerUur) <  $(vE_OrderTripNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))\r\n\n\n\n",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        omzetperuurexfuel: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzetPerUur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderTripOmzetPerUurExFuel)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderTripOmzetPerUurExFuel)'",
              "autoSort": true,
              "cId": "MJZcvk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderTripOmzetPerUurExFuel) < 0.85 * $(vE_OrderTripNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_OrderTripOmzetPerUurExFuel) <  $(vE_OrderTripNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))\r\n\n\n\n",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        normomzetperuur:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "=$(vE_OrderTripNormTurnoverHour)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_OrderNormTurnoverHour)'",
              "autoSort": true,
              "cId": "qcGe",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        omzetperuurvolgende:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_OrderTripOmzetPerUurCombinatieVolgendeOrder)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "= '$(vL_KPI_OrderTripOmzetPerUurCombinatieVolgendeOrder)'",
              "autoSort": true,
              "cId": "DKbBEk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderTripOmzetPerUurCombinatieVolgendeOrder) < 0.85 * $(vE_OrderTripNormTurnoverHour) ,rgb(255,255,255), if($(vE_KPI_OrderTripOmzetPerUurCombinatieVolgendeOrder) <  $(vE_OrderTripNormTurnoverHour) ,RGB(0,0,0), RGB(0,0,0)))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              },
              {
                "qExpression": "if($(vE_KPI_OrderTripOmzetPerUurCombinatieVolgendeOrder) < 0.85 * $(vE_OrderTripNormTurnoverHour) ,rgb(255,0,0), if($(vE_KPI_OrderTripOmzetPerUurCombinatieVolgendeOrder) <  $(vE_OrderTripNormTurnoverHour) ,RGB(255,242,0), RGB(34,177,76))) //RGB(255,127,39)",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellBackgroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        bezetting:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "=$(vE_KPI_OrderTripBezetting)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_OrderTripBezetting)'",
              "autoSort": true,
              "cId": "TCNkRn",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderTripBezetting) < 0 ,rgb(255,0,0), RGB(34,177,76))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    outsourced: {
      percentage: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripTripOutsourced)\t",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripTripOutsourced)'",
          "autoSort": true,
          "cId": "fPNrVLL",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      marge: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripCharterMarge)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderCharterMarge)'",
          "autoSort": true,
          "cId": "jfPSwtw",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_OrderTripCharterMarge) < 0, rgb(176,23,31), if($(vE_KPI_OrderTripCharterMarge) < $(vE_OrderTripNormCharter) ,rgb(244,164,96), rgb(46,139,87)))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      norm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripNormCharter)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderNormCharter)",
          "autoSort": true,
          "cId": "VehHD",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    },
    potentie: {
      totaal: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripPotency)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripPotency)' & ' > ' & '$(vB_Alle)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      operationeel: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripPotency(Kind={'Operational'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripPotency)' & ' > ' & '$(vB_Operationeel)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      commercieel: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripPotency(Kind={'Commercial'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripPotency)' & ' > ' & '$(vB_Commercieel)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    },
    km: {
      omzetperkm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "=$(vE_KPI_OrderTripOmzetPerKM)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripOmzetPerKM)'",
          "autoSort": true,
          "cId": "PZLtX",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_OrderTripOmzetPerKM) < $(vE_OrderTripNormTurnoverKM) ,rgb(255,0,0), rgb(0,127,0))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      omzetperkmfull: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripOmzetPerKMFull)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderOmzetPerKMFull))'",
          "autoSort": true,
          "cId": "RtDbpmg",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_OrderTripOmzetPerKMFull) < $(vE_OrderTripNormTurnoverKM) ,rgb(255,0,0), rgb(0,127,0))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      belading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "=$(vE_KPI_OrderTripBelading)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripBelading)'",
          "autoSort": true,
          "cId": "XsjRvj",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      km: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripKM)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='KM >' &  '$(vB_Alle)'",
          "autoSort": true,
          "cId": "eyFxN",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      kmfull: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripKM(Kind={'Full'} ))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='KM >' &  '$(vB_Full)'",
          "autoSort": true,
          "cId": "DDXhmkT",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      kmempty: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripKM(Kind={'Empty'} ))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='KM >' &  '$(vB_Empty)'",
          "autoSort": true,
          "cId": "jcpszEx",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      normomzetperkm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripNormTurnoverKM)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderNormTurnoverKM)'",
          "autoSort": true,
          "cId": "WjmPM",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      normomzetperkmfull: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripNormTurnoverKMFull)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripNormTurnoverKMFull)'",
          "autoSort": true,
          "cId": "vQmHLwc",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    },
    uren: {
      totaal: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripUren)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUren)'  & ' > ' & '$(vB_Alle)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      loading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripUren(Kind={'Loading'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUren)'  & ' > ' & '$(vB_Loading)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      unloading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripUren(Kind={'Unloading'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUren)'  & ' > ' & '$(vB_Unloading)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      driving: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripUren(Kind={'Driving'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUren)'  & ' > ' & '$(vB_Driving)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      cleaning: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripUren(Kind={'Cleaning'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUren)'  & ' > ' & '$(vB_Cleaning)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      other: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripUren(Kind={'Other'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUren)'  & ' > ' & '$(vB_Other)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_totaal: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripUrenAvg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUrenAvg)'  & ' > ' & '$(vB_Alle)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_loading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripUrenAvg(Kind={'Loading'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUrenAvg)'  & ' > ' & '$(vB_Loading)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_unloading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripUrenAvg(Kind={'Unloading'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUrenAvg)'  & ' > ' & '$(vB_Unloading)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_driving: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripUrenAvg(Kind={'Driving'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUrenAvg)'  & ' > ' & '$(vB_Driving)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_cleaning: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripUrenAvg(Kind={'Cleaning'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUrenAvg)'  & ' > ' & '$(vB_Cleaning)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_other: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripUrenAvg(Kind={'Other'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_OrderUrenAvg)'  & ' > ' & '$(vB_Other)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    },
    previousyear: {
      omzet: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripOmzet(Jaar={\"$(=Only(Jaar)-1)\"}))\r\n\n",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripOmzet) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      orders: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripAantalOrders(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripAantalOrders) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      ritten: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripAantalRitten(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripAantalRitten) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      normmarge: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripNormMarginPercent(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripNormMarginPercent) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      normmargeomzet: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripNormMarginOverOmzet(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripNormMarginOverOmzet) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      goedkeur: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripGoedkeur(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripGoedkeur) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      omzetperuur: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripOmzetPerUur(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripOmzetPerUur) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      uitbesteed: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripTripOutsourced(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripTripOutsourced) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      volgendeorder: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripOmzetPerUurCombinatieVolgendeOrder(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripOmzetPerUurCombinatieVolgendeOrder) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      chargermarge: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripCharterMarge(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripCharterMarge) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      lm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripLM(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripLM)  ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_lm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripLMAvg(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripLMAvg) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      kg: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripKG(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripKG) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_kg: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripKGAvg(Jaar={\"$(=Only(Jaar)-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripKGAvg) ' & (only(Jaar) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
    },
    persize: {
      lm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripLM)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripLM)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_lm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripLMAvg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripLMAvg)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      kg: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripKG)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripKG)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_kg: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripKGAvg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripKGAvg)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      pp: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripPP)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripPP)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_pp: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripPPAvg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripPPAvg)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      colli: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripColli)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripColli)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_colli: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripColliAvg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripColliAvg)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      shipmentsize: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderTripShipmentSize)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderTripShipmentSize)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      omzet_shipmentsize: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripOmzetPerZendingGrootte)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripOmzetPerZendingGrootte)'",
          "autoSort": true,
          "cId": "DtBnG",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      loading_shipmentsize: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripLaadMinutenPerZendingGrootte)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripLaadMinutenPerZendingGrootte)'",
          "autoSort": true,
          "cId": "JaYwj",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      unloading_shipmentsize: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripLosMinutenPerZendingGrootte)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripLosMinutenPerZendingGrootte)'",
          "autoSort": true,
          "cId": "gZBJWx",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      time_shipmentsize: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_OrderTripMinutenPerZendingGrootte)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_OrderTripMinutenPerZendingGrootte)'",
          "autoSort": true,
          "cId": "EdnRYS",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      
        vehicle: {
            number: {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Vehicle.Number)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Vehicle.Number)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            name: {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Vehicle.Name)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Vehicle.Name)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            license:   {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Vehicle.License)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Vehicle.License)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            type:   {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.Vehicle.Type)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.Vehicle.Type)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              }
        },
        driver: {
            name: {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Driver.Name)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Driver.Name)'",
                  "autoSort": true,
                  "cId": "PTfLLAx",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            number:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Driver.Number)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Driver.Number)'",
                  "autoSort": true,
                  "cId": "TbJcWp",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              }
        },
        trailer: {
            number: {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.Trailer.Number)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": true,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.Trailer.Number)'",
                  "autoSort": true,
                  "cId": "HYQARQ",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            license:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.Trailer.License)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": true,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.Trailer.License)'",
                  "autoSort": true,
                  "cId": "HYQARQ",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              }
        },
        charter: {
            number: {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.Creditor.Number)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": true,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.Creditor.Number)'",
                  "autoSort": true,
                  "cId": "PppBJ",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
            },
            name:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.Creditor.Name)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.Creditor.Name)'",
                  "autoSort": true,
                  "cId": "FpWZDHb",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
            },
            searchcode:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.Creditor.SearchCode)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.Creditor.SearchCode)'",
                  "autoSort": true,
                  "cId": "FpWZDHb",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
            },
            kinddescription:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.Creditor.Kind.Description)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.Creditor.Kind.Description)'",
                  "autoSort": true,
                  "cId": "FpWZDHb",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
            },
            type:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.Creditor.Type)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.Creditor.Type)'",
                  "autoSort": true,
                  "cId": "FpWZDHa",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
            }
        },
        trip: {
            number: {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.TripNumber)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.TripNumber)'",
                  "autoSort": true,
                  "cId": "AgsfAj",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
            },
            resourcecombination:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.ResourceCombination)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.ResourceCombination)'",
                  "autoSort": true,
                  "cId": "AgsfAj",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
            },
            companyname:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.Company.Name)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.Company.Name)'",
                  "autoSort": true,
                  "cId": "AgsfAj",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            intercompany:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.Company.Intercompany)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.Company.Intercompany)'",
                  "autoSort": true,
                  "cId": "AgsfAj",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            nrofcodrivers:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.NrOfCoDriver)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.NrOfCoDriver)'",
                  "autoSort": true,
                  "cId": "dYGPH",
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },      
            plangroup:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.PlanGroup)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.PlanGroup)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            plandivision:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.PlanDivision)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.PlanDivision)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            planregio:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Trip.PlanRegio)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Trip.PlanRegio)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              }
        },
        startlocation: {
            id: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "[$(vD_Trip.StartLocation.ID)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.ID)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            name: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "[$(vD_Trip.StartLocation.Name)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.Name)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            adress: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "[$(vD_Trip.StartLocation.Adress)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.Adress)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            searchcode: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "[$(vD_Trip.StartLocation.SearchCode)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.SearchCode)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            zipcode: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "[$(vD_Trip.StartLocation.ZIPcode)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.ZIPcode)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            city: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "[$(vD_Trip.StartLocation.City)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.City)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            district: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.StartLocation.District)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.District)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            province: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.StartLocation.Province)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.Province)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            region: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.StartLocation.Region)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.Region)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            financialregion: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.StartLocation.FinancialRegion)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.FinancialRegion)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            financialregiondescription: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.StartLocation.FinancialRegionDescription)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.FinancialRegionDescription)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            operationalregion: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.StartLocation.OperationalRegion)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.OperationalRegion)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            operationalregiondescription: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.StartLocation.OperationalRegionDescription)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.OperationalRegionDescription)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            country: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.StartLocation.Country)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.Country)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            land: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.StartLocation.Land)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.StartLocation.Land)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
        },
        endlocation: {
            id: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "[$(vD_Trip.EndLocation.ID)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.ID)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            name: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "[$(vD_Trip.EndLocation.Name)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.Name)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            adress: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "[$(vD_Trip.EndLocation.Adress)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.Adress)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            searchcode: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "[$(vD_Trip.EndLocation.SearchCode)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.SearchCode)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            zipcode: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "[$(vD_Trip.EndLocation.ZIPcode)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.ZIPcode)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            city: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "[$(vD_Trip.EndLocation.City)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.City)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            district: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.EndLocation.District)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.District)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            province: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.EndLocation.Province)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.Province)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            region: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.EndLocation.Region)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.Region)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            financialregion: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.EndLocation.FinancialRegion)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.FinancialRegion)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            financialregiondescription: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.EndLocation.FinancialRegionDescription)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.FinancialRegionDescription)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            operationalregion: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.EndLocation.OperationalRegion)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.OperationalRegion)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            operationalregiondescription: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.EndLocation.OperationalRegionDescription)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.OperationalRegionDescription)'",
                    "autoSort": true,
                    "othersLabel": "Others",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Others"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            country: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.EndLocation.Country)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.Country)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
            land: {
                "qLibraryId": "",
                "qDef": {
                    "qGrouping": "N",
                    "qFieldDefs": [
                        "=[$(vD_Trip.EndLocation.Land)]"
                    ],
                    "qFieldLabels": [
                        ""
                    ],
                    "qSortCriterias": [
                        {
                            "qSortByState": 0,
                            "qSortByFrequency": 0,
                            "qSortByNumeric": 1,
                            "qSortByAscii": 1,
                            "qSortByLoadOrder": 1,
                            "qSortByExpression": 0,
                            "qExpression": {
                                "qv": ""
                            },
                            "qSortByGreyness": 0
                        }
                    ],
                    "qNumberPresentations": [],
                    "qReverseSort": false,
                    "qActiveField": 0,
                    "qLabelExpression": "='$(vD_Trip.EndLocation.Land)'",
                    "autoSort": true,
                    "othersLabel": "Overige",
                    "textAlign": {
                        "auto": true,
                        "align": "left"
                    },
                    "representation": {
                        "type": "text",
                        "urlLabel": ""
                    }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                    "qOtherMode": "OTHER_OFF",
                    "qOtherCounted": {
                        "qv": "10"
                    },
                    "qOtherLimit": {
                        "qv": "0"
                    },
                    "qOtherLimitMode": "OTHER_GE_LIMIT",
                    "qSuppressOther": false,
                    "qForceBadValueKeeping": true,
                    "qApplyEvenWhenPossiblyWrongResult": true,
                    "qGlobalOtherGrouping": false,
                    "qOtherCollapseInnerDimensions": false,
                    "qOtherSortMode": "OTHER_SORT_DESCENDING",
                    "qTotalMode": "TOTAL_OFF",
                    "qReferencedExpression": {
                        "qv": ""
                    }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                    "qv": "Overige"
                },
                "qTotalLabel": {
                    "qv": ""
                },
                "qCalcCond": {
                    "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                    "qCond": {
                        "qv": ""
                    },
                    "qMsg": {
                        "qv": ""
                    }
                }
            },
        },
        period: {
            year: {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Year)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Year)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            quarter:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Quarter)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Quarter)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            month:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Month)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Month)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            period:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Period)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Period)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            week:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Week)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Week)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            yearweek:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_YearWeek)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_YearWeek)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            date:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Date)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Date)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              },
            weekday:  {
                "qLibraryId": "",
                "qDef": {
                  "qGrouping": "N",
                  "qFieldDefs": [
                    "[$(vD_Weekday)]"
                  ],
                  "qFieldLabels": [
                    ""
                  ],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": {
                        "qv": ""
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                  "qNumberPresentations": [],
                  "qReverseSort": false,
                  "qActiveField": 0,
                  "qLabelExpression": "='$(vD_Weekday)'",
                  "autoSort": true,
                  "othersLabel": "Overige",
                  "textAlign": {
                    "auto": true,
                    "align": "left"
                  },
                  "representation": {
                    "type": "text",
                    "urlLabel": ""
                  }
                },
                "qNullSuppression": false,
                "qIncludeElemValue": false,
                "qOtherTotalSpec": {
                  "qOtherMode": "OTHER_OFF",
                  "qOtherCounted": {
                    "qv": "10"
                  },
                  "qOtherLimit": {
                    "qv": "0"
                  },
                  "qOtherLimitMode": "OTHER_GE_LIMIT",
                  "qSuppressOther": false,
                  "qForceBadValueKeeping": true,
                  "qApplyEvenWhenPossiblyWrongResult": true,
                  "qGlobalOtherGrouping": false,
                  "qOtherCollapseInnerDimensions": false,
                  "qOtherSortMode": "OTHER_SORT_DESCENDING",
                  "qTotalMode": "TOTAL_OFF",
                  "qReferencedExpression": {
                    "qv": ""
                  }
                },
                "qShowTotal": false,
                "qShowAll": false,
                "qOtherLabel": {
                  "qv": "Overige"
                },
                "qTotalLabel": {
                  "qv": ""
                },
                "qCalcCond": {
                  "qv": ""
                },
                "qAttributeExpressions": [],
                "qAttributeDimensions": [],
                "qCalcCondition": {
                  "qCond": {
                    "qv": ""
                  },
                  "qMsg": {
                    "qv": ""
                  }
                }
              }
            }
        }
    }
;


export {measures};