const dimensions = {
    order: {
      companyorderrecievednr: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.Company.OrderOntvangenNr)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.Company.OrderOntvangenNr)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      companyorderrecievedomschr: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.Company.OrderOntvangenOmschr)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.Company.OrderOntvangenOmschr)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      companyorderexcecutednr: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.Company.OrderUitgevoerdNr)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.Company.OrderUitgevoerdNr)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      companyorderexcecutedomschr: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.Company.OrderUitgevoerdOmschr)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.Company.OrderUitgevoerdOmschr)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
    },
};

const measures = {
  km: {
    gecalculeerd: {
      "qLibraryId": "",
      "qDef": {
        "qLabel": "",
        "qDescription": "",
        "qTags": [],
        "qGrouping": "N",
        "qDef": "Num(Sum([Order.Calculated.Distance]),'###.###,')",
        "qNumFormat": {
          "qType": "U",
          "qnDec": 10,
          "qUseThou": 0,
          "qFmt": "",
          "qDec": "",
          "qThou": ""
        },
        "qRelative": false,
        "qBrutalSum": false,
        "qAggrFunc": "Expr",
        "qAccumulate": 0,
        "qReverseSort": false,
        "qActiveExpression": 0,
        "qExpressions": [],
        "qLabelExpression": "='KM >' &  'Gecalculeerde rechtstreekse afstand order'",
        "autoSort": true,
        "cId": "eUNc",
        "numFormatFromTemplate": true,
        "textAlign": {
          "auto": true,
          "align": "left"
        }
      },
      "qSortBy": {
        "qSortByState": 0,
        "qSortByFrequency": 0,
        "qSortByNumeric": -1,
        "qSortByAscii": 0,
        "qSortByLoadOrder": 1,
        "qSortByExpression": 0,
        "qExpression": {
          "qv": ""
        },
        "qSortByGreyness": 0
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCond": {
        "qv": ""
      },
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
  },
};
export {dimensions,measures};