import React from 'react';

import orderList from './../../margemonitor/components/orders/dimensions-CUR';
import ritList from './../../margemonitor/components/ritten/dimensions-CUR';
import orderTripList from './../../margemonitor/components/trajecten/dimensions-CUR';

const KPI1 = React.lazy(() => import('./../../views/Maatwerk/CUR/KPI1'));
const KPI2 = React.lazy(() => import('./../../views/Maatwerk/CUR/KPI2'));//import('./../../views/Maatwerk/CUR/KPI2'));

const Toerekening = React.lazy(() => import('./../../views/Maatwerk/CUR/Toerekening'));
const KM_aansluiting = React.lazy(() => import('./../../views/Maatwerk/CUR/KM-aansluiting'));
const KM_stand = React.lazy(() => import('./../../views/Maatwerk/CUR/KM-stand'));
const Klantrapport = React.lazy(() => import('./../../views/Maatwerk/CUR/Klantrapport'));


const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
    qlikobject: {
      boordcomputer: 'qlikCurfs',
    },
  config: {
    host: 'qliksense.curfs.com',
   // host: 'localhost',
    secure: true,
    //port: 443,
    prefix: '',
    appId: '5e768c18-63b2-4606-a8f0-cfa9adc7f1b8',
    //appId: 'c7b1979a-f530-458d-bd51-b57ec72f47fd',
  },
  connections: {
    qlikCloud: false,
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },


  // const options = {
  //   dimensions: {
  //     order: orderList,
  //     rit: ritList,
  //     traject: orderTripList,
  //   },
  //   qlikobject: {
  //     boordcomputer: 'qlikCurfs',
  //   },
  //   config: {
  //     host: 'transportcostconsult.eu.qlikcloud.com',
  //     secure: true,
  //     prefix: '',
  //     appId: '530ee398-de7d-4aed-bc3f-96150c5b9550',
  //     webIntegrationId: 'wfBOPyDC_a5jTUEQ_n4fW4kdwQvNGR81',  
  //   },
  //   connections: {
  //     qlikCloud: true,
  //     vizApi: true,
  //     engineApi: true,
  //     useUniqueSessionID: 'MargeMonitor'
  //   },


  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Year)]'],
            placeholder: 'Jaar',
            expanded: true, 
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Quarter)]'],
            placeholder: 'Kwartaal',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Month)]'],
            placeholder: 'Maand',
            expandedHorizontal: true,
            expanded: true, 
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Week)]'],
            placeholder: 'Week',
            autoSortByState: 0,
          },
        },  
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Date)]'],
            placeholder: 'Datum',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Order Laadlocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Adress)]'],
            placeholder: 'Adres',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'title',
          title: 'Order Loslocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Adress)]'],
            placeholder: 'Adres',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
      ],
    },
    order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Ordernummer',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Klant'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Name)]'],
            placeholder: 'Klantnaam',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'DebiteurNummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.DebiteurGroep)]'],
            placeholder: 'Debiteur Groep',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: 'Afdeling'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.KindDescription)]'],
            placeholder: 'Order soort',
            showStateInDropdown: true,
          },
        }
      ],
    },
    trip: {
      replace: false,
      filters: [
        { 
          type: 'title',
          title: "Rit" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Rit nummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Auto nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Auto naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Chauffeur nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Chauffeur naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[_ind_Trip.Outsourced]'],
            placeholder: 'Uitbesteed',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Kind.Description)]'],
            placeholder: 'Chartersoort',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Crediteur nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Crediteur naam',
            showStateInDropdown: true,
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: [
        {
          title: true,
          name: '',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
        {
          title: true,
          name: 'Maatwerk',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
        {
          name: 'KPI',
          url: '/cur/kpi1',
          icon: 'fa fa-tachometer-alt',
        },
        {
          name: 'KPI',
          url: '/cur/kpi2',
          icon: 'fa fa-tachometer-alt',
        },
        {
          name: 'Toerekening',
          url: '/cur/toerekening',
          icon: 'fa fa-calculator',
        },
        {
          name: 'KM stand',
          url: '/cur/km-stand',
          icon: 'fa fa-tachometer-alt',
        },
        {
          name: 'KM aansluiting',
          url: '/cur/km-aansluiting',
          icon: 'fa fa-check',
        },
        {
          name: 'Klantrapport',
          url: '/cur/klantrapport',
          icon: 'fa fa-briefcase',
        },
      ],
  },    
  routes: [
    { path: '/cur/kpi1', name: 'CUR / KPI', component: KPI1 },
    { path: '/cur/kpi2', name: 'CUR / KPI', component: KPI2 },
    { path: '/cur/toerekening', name: 'CUR / Toerekening', component: Toerekening },
    { path: '/cur/km-stand', name: 'CUR / KM stand', component: KM_stand },
    { path: '/cur/km-aansluiting', name: 'CUR / KM aansluiting', component: KM_aansluiting },
    { path: '/cur/klantrapport', name: 'CUR / KM aansluiting', component: Klantrapport },
  ],
  fieldSettings:{
    period: 'vD_Month'
  },
  selectionBar: 'Capability',
  rollingPeriod: false,
  defaultSelections: [
    {
      field: 'Jaar',
      expression: '=year(today())'
    }
  ]
};
export default options;
