import {dimensions, measures} from './qlik/qlik';
export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Chauffeur',
        value: 'driver',
        children: [
          {
            qlik: dimensions.driver.name, 
            label: 'Naam',
            value: 'vD_Driver.Name'
          },
          {
            qlik: dimensions.driver.number, 
            label: 'Nummer',
            value: 'vD_Driver.Number'
          }
        ]
      },
      {
        label: 'Auto',
        value: 'vehicle',
        children: [
          {
            qlik: dimensions.vehicle.number, 
            label: 'Nummer',
            value: 'vD_Vehicle.Number'
          },
          {
            qlik: dimensions.vehicle.name, 
            label: 'Naam',
            value: 'vD_Vehicle.Name'
          }
        ]
      },
      {
        label: 'Crediteur',
        value: 'crediteur',
        children: [
          {
            qlik: dimensions.charter.number, 
            label: 'Nummer',
            value: 'vD_Trip.Creditor.Number'
          },
          {
            qlik: dimensions.charter.name, 
            label: 'Naam',
            value: 'vD_Trip.Creditor.Name'
          }
        ]
      },
      {
        label: 'Trailer',
        value: 'trailer',
        children: [
          {
            qlik: dimensions.trailer.number, 
            label: 'Nummer',
            value: 'vD_Trip.Trailer.Number'
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            qlik: dimensions.trip.number, 
            value: 'vD_Trip.TripNumber',
            label: 'Rit Nummer'
          }
        ]
      },
      {
        label: 'Start Locatie',
        value: 'laadlocatie',
        children: [
          {
            qlik: dimensions.startlocation.id, 
            value: 'vD_Trip.StartLocation.ID',
            label: 'ID'
          },
          {
            qlik: dimensions.startlocation.name, 
            value: 'vD_Trip.StartLocation.Name',
            label: 'Naam'
          },
          {
            qlik: dimensions.startlocation.adress,
            value: 'vD_Trip.StartLocation.Adress',
            label: 'Adres'
          },
          {
            qlik: dimensions.startlocation.zipcode,
            value: 'vD_Trip.StartLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            qlik: dimensions.startlocation.city, 
            value: 'vD_Trip.StartLocation.City',
            label: 'Plaats'
          },
          {
            qlik: dimensions.startlocation.district,
            value: 'vD_Trip.StartLocation.District',
            label: 'Gemeente'
          },
          {
            qlik: dimensions.startlocation.country,
            value: 'vD_Trip.StartLocation.Country',
            label: 'Land'
          }
        ]
      },
      {
        label: 'Eind Locatie',
        value: 'loslocatie',
        children: [
          {
            qlik: dimensions.endlocation.id, 
            value: 'vD_Trip.EndLocation.ID',
            label: 'ID'
          },
          {
            qlik: dimensions.endlocation.name,    
            value: 'vD_Trip.EndLocation.Name',
            label: 'Naam'
          },
          {
            qlik: dimensions.endlocation.adress,
            value: 'vD_Trip.EndLocation.Adress',
            label: 'Adres'
          },
          {
            qlik: dimensions.endlocation.zipcode,
            value: 'vD_Trip.EndLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            qlik: dimensions.endlocation.city, 
            value: 'vD_Trip.EndLocation.City',
            label: 'Plaats'
          },
          {
            qlik: dimensions.endlocation.district,
            value: 'vD_Trip.EndLocation.District',
            label: 'Gemeente'
          },
          {
            qlik: dimensions.endlocation.country,
            value: 'vD_Trip.EndLocation.Country',
            label: 'Land'
          },
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            qlik: dimensions.period.year, 
            value: 'vD_Year',
            label: 'Jaar'
          },
          { 
            qlik: dimensions.period.quarter, 
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            qlik: dimensions.period.month, 
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            qlik: dimensions.period.week, 
            value: 'vD_Week',
            label: 'Week'
          },
          {
            qlik: dimensions.period.date, 
            value: 'vD_Date',
            label: 'Datum'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            qlik: measures.omzet.totaal, 
            value: 'vE_TripOmzet',
            label: 'Totaal'
          },
          {
            qlik: measures.omzet.freight,
            value: 'vE_TripOmzetFreight',
            label: 'Vracht'
          },
          {
            qlik: measures.omzet.fuel,
            value: 'vE_TripOmzetFuel',
            label: 'Brandstof'
          },
          {
            qlik: measures.omzet.other,
            value: 'vE_TripOmzetOther',
            label: 'Overig'
          }
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            qlik: measures.kosten.totaal, 
            value: 'vE_TripKosten',
            label: 'Totaal',
          },
          {
            qlik: measures.kosten.charter, 
            value: 'vE_TripKostenCharter',
            label: 'Charter',
          },
          {
            qlik: measures.kosten.toll, 
            value: 'vE_TripKostenTol',
            label: 'Tol',
          },
          {
            qlik: measures.kosten.warehouse, 
            value: 'vE_TripKostenWarehouse',
            label: 'Warehouse',
          },
          {
            qlik: measures.kosten.other, 
            value: 'vE_TripKostenOther',
            label: 'Other',
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            qlik: measures.aantal.dossiers, 
            value: 'vE_TripAantalDossiers',
            label: 'Dossiers',
          },
          {
            qlik: measures.aantal.orders, 
            value: 'vE_TripAantalOrders',
            label: 'Zendingen',
          },
          {
            qlik: measures.aantal.ritten, 
            value: 'vE_TripAantalRitten',
            label: 'Ritten',
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            qlik: measures.kpi.goedkeur, 
            value: 'vE_KPI_TripGoedkeur',
            label: 'OK'
          },

          {
            qlik: measures.kpi.normmarginpercent,
            value: 'vE_KPI_TripNormMarginPercent',
            label: 'Norm Marge %'
          },
          {
            qlik: measures.kpi.normmarginomzet, 
            value: 'vE_KPI_TripNormMarginOverOmzet',
            label: 'Norm Marge'
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            qlik: measures.own.omzetperuur,
            value: 'vE_KPI_TripOmzetPerUur',
            label: 'Omzet / Uur'
          },
          {
            qlik: measures.own.normomzetperuur,
            value: 'vE_TripNormTurnoverHour',
            label: 'Norm Omzet / Uur'
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            qlik: measures.outsourced.percentage,
            value: 'vE_KPI_TripTripOutsourced',
            label: '% Uitbesteed'
          },
          {
            qlik: measures.outsourced.marge,
            value: 'vE_KPI_TripCharterMarge',
            label: 'Derden Marge'
          },
          {
            qlik: measures.outsourced.norm, 
            value: 'vE_TripNormCharter',
            label: 'Norm'
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            qlik: measures.km.totaal, 
            value: 'vE_TripKM',
            label: 'Totaal'
          },
          {
            qlik: measures.km.omzetperkm,
            value: 'vE_KPI_TripOmzetPerKM',
            label: 'Omzet / KM'
          },
          {
            qlik: measures.km.normomzetperkm,
            value: 'vE_TripNormTurnoverKM',
            label: 'Norm Omzet / KM'
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            qlik: measures.uren.totaal, 
            value: 'vE_TripUren',
            label: 'Totaal (Σ)',
          },
          {
            qlik: measures.uren.loading,
            value: 'vE_TripUrenLoading',
            label: 'Laden (Σ)',
          },
          {
            qlik: measures.uren.unloading, 
            value: 'vE_TripUrenUnloading',
            label: 'Lossen (Σ)',
          },
          {
            qlik: measures.uren.driving, 
            value: 'vE_TripUrenDriving',
            label: 'Rijden (Σ)',
          },
          {
            qlik: measures.uren.other, 
            value: 'vE_TripUrenOther',
            label: 'Overig (Σ)',
          },
          {
            qlik: measures.uren.avg_totaal,
            value: 'vE_TripUrenAvg',
            label: 'Totaal (avg)',
          },
          {
            qlik: measures.uren.avg_loading,
            value: 'vE_TripUrenAvgLoading',
            label: 'Laden (avg)',
          },
          {
            qlik: measures.uren.unloading,
            value: 'vE_TripUrenAvgUnloading',
            label: 'Lossen (avg)',
          },
          {
            qlik: measures.uren.avg_driving,
            value: 'vE_TripUrenAvgDriving',
            label: 'Rijden (avg)',
          },
          {
            qlik: measures.uren.avg_other,
            value: 'vE_TripUrenAvgOther',
            label: 'Overig (avg)',      // $(wActive(Order, 'M_Uren_Rijden_A'))
          }
        ]
      }
    ]
  }
];
