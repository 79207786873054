const dimensions = {
  traject: {
    loadingsorter: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[$(vD_OrderTripDetails.Loading.Volgorde)]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='$(vD_OrderTripDetails.Loading.Volgorde)'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    unloadingsorter: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[$(vD_OrderTripDetails.Unloading.Volgorde)]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='$(vD_OrderTripDetails.Unloading.Volgorde)'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    type: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[$(vD_OrderTripDetails.TrajectType)]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='$(vD_OrderTripDetails.TrajectType)'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    }
  },
};

export {dimensions};